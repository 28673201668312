import React from "react";
import Moment from 'moment';

const Footer = props => {
// eslint-disable-next-line
    const {  title } = props;

    return (
        <>
            <footer className="footer fixed-bottom" style={{backgroundColor:'#073487','minHeight':'40px'}}>
                <div className="container-fluid">
                    <div className="row mt-1 d-none d-sm-none d-md-block">
                        <div className='col-12 text-center text-white' style={{fontSize:'14px'}}>โรงเรียนวัฒโนทัยพายัพ จังหวัดเชียงใหม่</div>
                        <div className='col-12 text-center text-white' style={{fontSize:'14px'}}>Powered by <a href="https://www.taila.co.th" className="text-decoration-none	text-white" target="_blank">Taila Solution Co.,Ltd</a></div>
                    </div>

                    <div className="row mt-1 d-block d-sm-block d-md-none">
                        <div className='col-12 text-white text-center' style={{fontSize:'10px'}}>โรงเรียนวัฒโนทัยพายัพ จังหวัดเชียงใหม่</div>
                        <div className='col-12 text-white text-center' style={{fontSize:'10px'}}>Powered by <a href="https://www.taila.co.th" className="text-decoration-none	text-white" target="_blank">Taila Solution Co.,Ltd</a></div>

                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;