import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {IsLogged} from '../../Utils/Function';
import HomeContent from './HomeContent';
import ApplicantContent from './ApplicantContent';
import '../../App.css';
const PageHome =()=> {

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);

    return (
        <>
        <React.Fragment>
            {(IsLogged() === false ?
            <HomeContent /> : <ApplicantContent />
            )}
            
        </React.Fragment>
        </>
    )
}

export default React.memo(PageHome)
