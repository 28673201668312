import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import '../../App.css';
const ApplicationFormPDF = forwardRef((props,ref) =>{
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({

      }));

      return (
        <>
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
            <Viewer fileUrl={props.PDFFile} plugins={[defaultLayoutPluginInstance]}/>
        </Worker>  
        </>
      )

});

export default React.memo(ApplicationFormPDF)