import React from "react";
import Layout from './Layout'

function App() {
  return (
    <React.Fragment>
      <Layout />
  </React.Fragment>
  );
}

export default App;
