import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PageHome,PageNotFound,ApplicantSignupPage,PageDev} from './Pages';
const Router =() =>{
    return (
        <Routes>
            <Route path="/" element={<PageHome />} />
            <Route path="/Applican/Signup" element={<ApplicantSignupPage />} />
            <Route path="/dev" element={<PageDev/>}/>
            <Route path="*" element={<PageNotFound/>} />
        </Routes>
    )
}

export default React.memo(Router)