import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";
import { Modal} from "reactstrap";
import { OTPCard } from '../../Components';
import {KeyDownNumber,KeyDownMobile} from '../../Utils/Function';
//import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import Swal from 'sweetalert2';
import API from '../../API';
import '../../App.css';


import { ThaiDatePicker } from "thaidatepicker-react";

const ApplicantSignupPage =()=> {
    const RedirectTo = useNavigate()
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();
    const [_IdentifyTypeID,setIdentifyTypeID] = useState(1);
    const [_IdentifyTypeName,setIdentifyTypeName] = useState('เลขประจำตัวประชาชนผู้สมัคร');
    const [_Mobile,setMobile] = useState('');
    const [_BirthDay,setBirthDay] = useState(null);
    const _refOTP = useRef();
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);

    const _IdentifyTypeChange = (e)=>{
        const _val = Number(e.target.value);
        setIdentifyTypeID(_val);
        switch(_val){
            case 1: 
                setIdentifyTypeName('เลขประจำตัวประชาชนผู้สมัคร')
            break;
            case 2: 
                setIdentifyTypeName('เลขประจำตัวคนซึ่งไม่มีสัญชาติไทยผู้สมัคร')
            break;
            case 3: 
                setIdentifyTypeName('เลขหนังสือเดินทางผู้สมัคร')
            break;
        }
    }
    const _Reset = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการยกเลิกลงทะเบียนหรือไม่ ?',
            showCancelButton : true,
            confirmButtonText: 'ใช่',
            confirmButtonColor:'#d33',
            cancelButtonText:'ไม่'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ResetValue();
            window.location.href='/';
        });
    }
    const _ResetValue = ()=>{
        reset();
    }
    const _Signup = ()=>{
        if(_Mobile.length != 10){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุหมายเลขโทรศัพท์มือถือ'
            });
            return;
        }
        if(getValues('IdentifyID') === undefined || getValues('IdentifyID') === null || getValues('IdentifyID').length === 0){
            Swal.fire({
                icon: 'error',
                title: _IdentifyTypeName
            });
            return;
        }
        if(getValues('PrefixNameID') == null || getValues('PrefixNameID') == undefined || Number(getValues('PrefixNameID')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุคำนำหน้าชื่อ'
            });
            return;
        }
        if(getValues('FirstName') === undefined || getValues('FirstName') === null || getValues('FirstName').length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุชื่อ'
            });
            return;
        }
        if(getValues('LastName') === undefined || getValues('LastName') === null || getValues('LastName').length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุนามสกุล'
            });
            return;
        }
        if(_BirthDay === undefined || _BirthDay === null ){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุวันเดือนปีเกิดผู้สมัคร'
            });
            return;
        }
        if(getValues('Email') != null && getValues('Email').length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(getValues('Email'))){
            Swal.fire({
                icon: 'error',
                title: 'รูปแบบ Email ไม่ถูกต้อง'
            });
            return;
        }

        if(getValues('RegTypeID') === undefined || getValues('RegTypeID') === null || isNaN(Number(getValues('RegTypeID'))) || Number(getValues('RegTypeID'))===0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุเลือกระดับชั้น'
            });
            return;
        }
        _refOTP.current.GetOTP(_Mobile);
    }

    const _SaveSignup = async(OTPRefID,RefCode)=>{
        const _reqObj = {
            IdentifyType:_IdentifyTypeID,
            IdentifyId:getValues('IdentifyID'),
            RegTypeID:getValues('RegTypeID'),
            BirthDay:_BirthDay,
            MobileNumber:_Mobile,
            Email:getValues('Email'),
            PrefixNameID:getValues('PrefixNameID'),
            FirstName:getValues('FirstName'),
            LastName:getValues('LastName'),
            OTPRef:OTPRefID,
        }

        sessionStorage.setItem('Authorization',null);
        sessionStorage.setItem('ApplicantProfile',null);
        sessionStorage.setItem('ApplicantID',null);
        sessionStorage.clear();

        API({method : "post",url:"/Applicant/Signup",data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                
                return;
            }
            sessionStorage.setItem("Authorization", res.data.Response.Authorization);
            sessionStorage.setItem("ApplicantID", res.data.Response.ApplicantID);
            sessionStorage.setItem("ApplicantProfile",JSON.stringify(res.data.Response) );

            Swal.fire({
                icon: 'success',
                title: res.data.Message,
                timer: 5000,
            }).then(()=>{
                window.location.href='/';
            });
        }).catch(error => {
            Swal.close();
            if(error.response != undefined){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.Message
                });
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: error.message+' ['+error.code+']',
                });
            }
            
        });
    }

    return (
        <>
        <React.Fragment>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>ลงทะเบียนรับระเบียบการสมัคร</h3>
                            </div>
                            <div className='card-body'>
                                <div className='row mt-2 mb-2'>
                                    <div className="mt-2 mb-2 col-md-4">
                                        <label className="form-label fw-semibold">ประเภทบัตรประจำตัวผู้สมัคร</label>
                                        <select className='form-control' value={_IdentifyTypeID} onChange={(e)=>{_IdentifyTypeChange(e);}}>
                                            <option value={1}>บัตรประจำตัวประชาชน</option>
                                            <option value={2}>บัตรประจำตัวคนซึ่งไม่มีสัญชาติไทย</option>
                                            <option value={3}>หนังสือเดินทาง</option>
                                        </select>
                                    </div>
                                    <div className="mt-2 mb-2 col-md-4">
                                        <label className="form-label fw-semibold">{_IdentifyTypeName}</label>
                                        <input type={_IdentifyTypeID === 1 ? "tel":"text"} className="form-control" onKeyDown={(e)=>{ if(_IdentifyTypeID == 1) KeyDownMobile(e);}} {...register("IdentifyID", { required: false })} autoComplete='one-time-code'/>
                                    </div>
                                    <div className="mt-2 mb-2 col-md-4">
                                        <label className="form-label fw-semibold">หมายเลขโทรศัพท์มือถือ</label>
                                        <input type="tel" pattern="[0-9]{3}-[0-9]{4}-[0-9]{3}" onKeyDown={(e)=>{KeyDownMobile(e);}} maxLength={10} value={_Mobile} onChange={(e)=>{setMobile(e.target.value);}} className="form-control" autoComplete='one-time-code'/>
                                    </div>
                                </div>
                                <div className='row mt-2 mt-2 mb-2'>
                                    <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                        <label className="form-label fw-semibold">คำนำหน้าชื่อ</label>
                                        <Controller name="PrefixNameID" control={control} rules={{required:false}} render={({field})=>(
                                            <select className='form-control' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                                <option value={0}>เลือกคำนำหน้าชื่อ</option>
                                                <option value={1}>เด็กชาย</option>
                                                <option value={2}>เด็กหญิง</option>
                                                <option value={3}>นาย</option>
                                                <option value={4}>นางสาว</option>
                                            </select>
                                        )}/>
                                    </div>
                                    <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-5'>
                                        <label className="form-label fw-semibold">ชื่อ</label>
                                        <input type="text" className="form-control" {...register("FirstName", { required: false })}  autoComplete='one-time-code'/>
                                    </div>
                                    <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-5'>
                                        <label className="form-label fw-semibold">นามสกุล</label>
                                        <input type="text" className="form-control" {...register("LastName", { required: false })}  autoComplete='one-time-code'/>
                                    </div>
                                    
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                        <label className="form-label fw-semibold">วันเดือนปีเกิด</label>
                                        <ThaiDatePicker inputProps={{displayFormat: "D MMMM YYYY",className:'text-center form-control' }} value={_BirthDay} onChange={(c,b)=>{
                                            setBirthDay(c);
                                        }}/>
                                    </div>
                                    <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-4'>
                                        <label className="form-label fw-semibold">Email</label>
                                        <input type="email" className="form-control" {...register("Email", { required: false })}  autoComplete='one-time-code'/>
                                    </div>
                                    <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                        <label className="form-label fw-semibold">ระดับชั้น</label>
                                        <Controller name="RegTypeID" control={control} rules={{required:false}} render={({field})=>(
                                            <select className='form-control' onChange={(e)=>field.onChange(e)} defaultValue={field.value} >
                                                <option value={0}>เลือกระดับชั้น</option>
                                                <option value={1}>ชั้นมัธยมศึกษาปีที่ 1</option>
                                                <option value={2}>ชั้นมัธยมศึกษาปีที่ 4</option>
                                            </select>
                                        )}/>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-12 text-center'>
                                        <div className='btn-group btn-group-lg'>
                                            <button type='button' className='btn btn-danger'style={{'width':'150px'}} onClick={()=>{_Reset();}}>ยกเลิก</button>
                                            <button type='button' className='btn btn-success'style={{'width':'150px'}} onClick={()=>{_Signup();}}>ลงทะเบียน</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OTPCard ref={_refOTP} Verifly={_SaveSignup}/>
        </React.Fragment>
        </>
    )
}

export default React.memo(ApplicantSignupPage)
