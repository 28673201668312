import React,{forwardRef,useImperativeHandle,useEffect, useState, useRef } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import moment from 'moment';
import {GetAPIURL,GetAuthorization} from '../../Utils/Function';
import {PrefixName} from '../../Utils/Prefix';
import {PDFViewCard} from '../../Components';
const RegisterView = forwardRef((props,ref) =>{
    const [_ModalViewState,setModalViewState] = useState(false);
    const [_RegulationID,setRegulationID] = useState(null);
    const [_ApplicationForm,setApplicationForm] = useState(null);
    const _PDFViewCard = useRef();
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    const _LoadApplicationForm = async(RegulationID)=>{
        Swal.showLoading();
        API({method : "get",url:"/Application/"+RegulationID}).then(res=>{
            if(res.data.Status){
                const _obj = res.data.Response;
                setApplicationForm(_obj);
                setModalViewState(true);
            }
            Swal.close();
        }).catch(error=>{
            Swal.close();
        });
    }
    useImperativeHandle(ref, () => ({
        Show(RegulationID){
            setRegulationID(RegulationID);
            _LoadApplicationForm(RegulationID);
        }
      }));

      return (
        <>
        <Modal isOpen={_ModalViewState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">รายละเอียดใบสมัครสอบ</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalViewState(false)}}></button>
            </div>
            <div className='modal-body' style={{minHeight:'300px'}}>
                <div className='row'>
                    <div className='col-lg-10'>
                        <div className='row'>
                            <div className='col-12 mt-1 mb-4 text-center d-block d-sm-block d-md-none'>
                                <img src={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Image'} style={{maxWidth:'150px',maxHeight:'200px'}} />
                            </div>
                            {(_ApplicationForm != null &&_ApplicationForm.CandidateCode != undefined && _ApplicationForm.CandidateCode != null ?
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">หมายเลขประจำตัวผู้สมัคร</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.CandidateCode:'')}</div>
                            </div>
                            :'')}
                            
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ชื่อ - นามสกุล</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? PrefixName(_ApplicationForm.PrefixNameID)+_ApplicationForm.FirstName+' '+_ApplicationForm.LastName:'')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">หมายเลขโทรศัพท์</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.Mobile:'')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ระดับชั้น</label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.RegTypeName:'')}</div>
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">สถานะ</label>
                                <div className='form-inline text-primary fw-bold'>{(_ApplicationForm != null ? _ApplicationForm.StatusName:'')}</div>
                                
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ใบสมัครสอบ </label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.RegRoundName:'')}</div>
                            </div>

                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12 fieldInline'>
                                <label className="form-label fw-semibold">ประเภทโครงการ </label>
                                <div className='form-inline'>{(_ApplicationForm != null ? _ApplicationForm.ProjectName:'')}</div>
                            </div>

                            <div className='mt-2 mb-2 col-12 text-center d-block d-sm-block d-md-none'>
                                <div className='btn-group btn-group-sm'>
                                    <button type='button' className='btn btn-info btn-sm text-white form-control' onClick={()=>{_PDFViewCard.current.Show(GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/PDF',(_ApplicationForm != null ? 'ใบสมัครสอบ '+PrefixName(_ApplicationForm.PrefixNameID)+_ApplicationForm.FirstName+' '+_ApplicationForm.LastName:undefined))}}>ดาว์โหลดใบสมัคร</button>
                                    {(_ApplicationForm != null &&_ApplicationForm.CandidateCode != undefined && _ApplicationForm.CandidateCode != null && _ApplicationForm.Candidate ?
                                        <button type='button' className='btn btn-warning btn-sm text-white form-control' onClick={()=>{_PDFViewCard.current.Show(GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Candidate',(_ApplicationForm != null ? 'บัตรประจำตัวผู้สมัคร '+PrefixName(_ApplicationForm.PrefixNameID)+_ApplicationForm.FirstName+' '+_ApplicationForm.LastName:undefined))}}>พิมพ์บัตรประจำตัวผู้สมัคร</button>
                                    :'')}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-2 text-center d-none d-sm-none d-md-block'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <img className='form-control' src={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Image'} style={{maxWidth:'250px',maxHeight:'200px'}} />
                            </div>
                        </div>
                        <div className='row mt-4 mb-2'>
                            <div className='col-12 text-center'>
                                <div className='btn-group btn-group-vertical'>
                                    <button type='button' className='btn btn-info btn-sm text-white' onClick={()=>{_PDFViewCard.current.Show(GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/PDF',(_ApplicationForm != null ? 'ใบสมัครสอบ '+PrefixName(_ApplicationForm.PrefixNameID)+_ApplicationForm.FirstName+' '+_ApplicationForm.LastName:undefined))}}>ดาว์โหลดใบสมัคร</button>
                                    {(_ApplicationForm != null &&_ApplicationForm.CandidateCode != undefined && _ApplicationForm.CandidateCode != null && _ApplicationForm.Candidate ?
                                        <button type='button' className='btn btn-warning btn-sm text-white form-control' onClick={()=>{_PDFViewCard.current.Show(GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/Candidate',(_ApplicationForm != null ? 'บัตรประจำตัวผู้สมัคร '+PrefixName(_ApplicationForm.PrefixNameID)+_ApplicationForm.FirstName+' '+_ApplicationForm.LastName:undefined))}}>พิมพ์บัตรประจำตัวผู้สมัคร</button>
                                    :'')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <PDFViewCard ref={_PDFViewCard}/>
        </>
      )
});

export default React.memo(RegisterView)