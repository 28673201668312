const PrefixName = (PrefixNameID)=>{
    switch(Number(PrefixNameID)){
        case 1 : return 'เด็กชาย';
        case 2 : return 'เด็กหญิง';
        case 3 : return 'นาย';
        case 4 : return 'นางสาว';
        default: return '';
    }
}

export {PrefixName}