import React,{forwardRef,useEffect, useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {DateThai,RegTypeName,GetAPIURL} from '../../Utils/Function';
import parse from 'html-react-parser';
import { PDFViewCard } from '../../Components';
import RegisterForm from './RegisterForm';
import RegisterView from './RegisterView';
import '../../App.css';

const ApplicantContent = forwardRef((props,ref) =>{
    const RedirectTo = useNavigate()
    const [_ApplicantDetail,setApplicantDetail] = useState(null);
    const [ApplicantID,setApplicantID] = useState(null);
    const [_AnnounceContent,setAnnounceContent] = useState('');
    const [_RegRoundList,setRegRoundList] = useState([]);
    const _RegisterForm = useRef();
    const _PDFViewCard = useRef();
    const _RegisterView = useRef();
    useEffect(() => {
        (async () => {
                setRegRoundList([]);
                let _Profile = JSON.parse(sessionStorage.getItem('ApplicantProfile'));
                setApplicantDetail(_Profile);
                setApplicantID(sessionStorage.getItem('ApplicantID'))
                _Announce();
                _GetRegRound();
        })();

        return ()=>{ }
    }, []);
    const _Announce = async()=>{
        API({method : "get",url:"/Announce/Last"}).then(res=>{
            setAnnounceContent(res.data);
        }).catch(error=>{

        });
    }
    const _GetRegRound = async()=>{
        setRegRoundList([]);
        Swal.isLoading();
        API({method : "get",url:"/Applicant/Items"}).then(res=>{
            if(res.data.Status){
                setRegRoundList(res.data.Response);
            }
            Swal.close();
        }).catch(error =>{
            Swal.close();
        });
    }
    const _ApplicantLogout = async()=>{

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการออกจากระบบหรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            sessionStorage.setItem('Authorization',null);
            sessionStorage.setItem('ApplicantProfile',null);
            sessionStorage.setItem('ApplicantID',null);
            sessionStorage.clear();
            window.location.reload();
        });
    }

    const _PDFView = async(RegRoundID,Title)=>{
        _PDFViewCard.current?.Show(GetAPIURL()+"/Applicant/RegRound/"+RegRoundID+"/PDF",Title);
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                <div className='col-lg-2 mt-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title fs-5 fw-semibold'>ข้อมูลผู้ลงทะเบียน</div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="mb-2">
                                                    <label className="form-label fw-semibold">ชื่อ</label>
                                                    <input type="text" readOnly className="form-control-plaintext text-end" value={(_ApplicantDetail != null ?_ApplicantDetail.FullName:'-')}/>
                                                </div>
                                                <div className="mb-3">
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">หมายเลขโทรศัพท์มือถือ</label>
                                                    <input type="text" readOnly className="form-control-plaintext text-end" value={(_ApplicantDetail != null ?_ApplicantDetail.MobileNumber:'-')}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">ระดับชั้น</label>
                                                    <input type="text" readOnly className="form-control-plaintext text-end" value={(_ApplicantDetail != null ? RegTypeName(_ApplicantDetail.RegTypeID):'-')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 text-end'>
                                                <button type='button' className='btn btn-danger btn-lg' style={{width:'150px'}}  onClick={()=>{_ApplicantLogout()}}>ออกจากระบบ</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-10 mt-2'>
                        <div className='row mb-4'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title fs-5 fw-semibold'>ระเบียบการและใบสมัคร</div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mt-2 mb-2 table-responsive overflow-auto'>
                                            {(_RegRoundList.map((v,i)=>{
                                                return (<>
                                                    <div key={i} className='col-12 mb-4'>
                                                        <div className='card'>
                                                            <div className='card-body' style={{'backgroundColor':'#edf8ff'}}>
                                                                <div className='row justify-content-between mb-2'>
                                                                    <div className='col-lg-6 mt-2 mb-2 fw-semibold col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>{v.RegRound.Name}</div>
                                                                    <div className='col-lg-6 col-md-8 mt-2 mb-2 fw-semibold fs-5 d-none d-sm-none d-md-block mt-2 mb-2'>{v.RegRound.Name}</div>
                    
                                                                    <div className='col-lg-4 col-12 text-end col-md-4'>
                                                                        <div className='btn btn-group btn-group-lg'>
                                                                            <button className='btn btn-info text-white' onClick={()=>{_PDFView(v.RegRoundID,v.RegRound.Name);}}>ระเบียบการ</button>
                                                                            {(v.RegulationID != null && (v.Status === 1 || v.Status === 5) && v.Active === true ? 
                                                                                <button className='btn btn-warning text-white' onClick={()=>{_RegisterForm.current.Show(v.RegRoundID,v.RegulationID);}}>แก้ไขข้อมูลการสมัคร</button>
                                                                            :"")}
                                                                            {(v.RegulationID === null && v.Active === true?
                                                                                <button className='btn btn-danger text-white' onClick={()=>{_RegisterForm.current.Show(v.RegRoundID);}}>กรอกใบสมัคร</button>
                                                                            :"")}
                                                                            {(v.RegulationID != null && v.Status != 5 && v.Status > 1 ? 
                                                                                <button className='btn btn-success text-white' onClick={()=>{_RegisterView.current.Show(v.RegulationID);}}>รายละเอียด</button>
                                                                            :"")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                    </div>
                                                                    <div className='col-lg-3 mt-2 mb-2'>
                                                                        {(v.Status != 0?<span className="badge bg-primary text-white" style={{'minWidth':'150px','minHeight':'34px'}}><label style={{'paddingTop':'5px','fontSize':'16px'}}>{(v.StatusName)}</label></span>:"")}
                                                                    </div>

                                                                </div>
                                                                {(v.RegulationID != null && v.CandidateCode != null?
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>หมายเลขประจำตัวผู้สมัคร :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>เลขประจำตัวผู้สมัคร :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {v.CandidateCode}
                                                                    </div>
                                                                </div>
                                                                :"")}
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>ระดับชั้น :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>ระดับชั้น :</span>
                                                                    </div>
                                                                    <div className='col-lg-3 mt-2 mb-2'><span className='ml-2'>{RegTypeName(v.RegRound.RegType)}</span></div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>รับระเบียบการ :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>รับระเบียบการ :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {DateThai(v.RegRound.RegulationStart)} - {DateThai(v.RegRound.RegulationEnd)}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>รับสมัคร :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>รับสมัคร :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {DateThai(v.RegRound.SubmitStart)} - {DateThai(v.RegRound.SubmitEnd)}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>ประกาศรายชื่อผู้มีสิทธิ์สอบ :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>ประกาศรายชื่อผู้มีสิทธิ์สอบ :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {DateThai(v.RegRound.Candidate)}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>สอบคัดเลือก :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>สอบคัดเลือก :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {DateThai(v.RegRound.ExamDate)}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>ประกาศผล :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>ประกาศผล :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {DateThai(v.RegRound.ExamResultDate)}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>ยืนยันสิทธิ์ :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>ยืนยันสิทธิ์ :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                        {DateThai(v.RegRound.ConfirmDate)}
                                                                    </div>
                                                                </div>
                                                                {(v.RegRound.Description != null ? 
                                                                <>
                                                                <div className='row mt-2'>
                                                                    <div className='col-lg-2 d-block d-sm-block d-md-none mt-2 mb-2 text-start'>
                                                                        <span className='fw-semibold'>รายละเอียด :</span>
                                                                    </div>
                                                                    <div className='col-lg-2 d-none d-sm-none d-md-block mt-2 mb-2 text-end'>
                                                                        <span className='fw-semibold'>รายละเอียด :</span>
                                                                    </div>
                                                                    <div className='col-lg-10 mt-2 mb-2'>
                                                                    {v.RegRound.Description}
                                                                    </div>
                                                                </div>
                                                                </>
                                                                :"")}
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='card-title fs-5 fw-semibold'> ข่าวประชาสัมพันธ์</div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row mt-2 mb-2'>
                                        {parse(_AnnounceContent)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
            <RegisterForm ref={_RegisterForm} reload={()=>{_GetRegRound()}} />
            <RegisterView ref={_RegisterView} />
            <PDFViewCard ref={_PDFViewCard}/>
        </>
    )
});
export default React.memo(ApplicantContent)