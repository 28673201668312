import React,{} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../App.css';
const Header =()=> {


    return (
        <>
        <header>
            <Navbar collapseOnSelect expand="lg" className="navbar navbar-light header fixed-top" style={{backgroundColor:'#073487'}} >
                <Container fluid>
                    <Navbar.Brand href="" style={{'padding':'0px 0px 0px 0px'}}>
                            <img className='navbar-brand' src={require('../Assets/Images/Wattanothai_Logo.png')} style={{'height':'65px','float':'left'}} alt='Logo'/>
                            <div className='text-left text-white d-block d-sm-block d-md-none ibm-plex-sans-thai-looped-extraligh' style={{'float':'left','paddingTop':'14px','fontSize':'12px'}}>งานรับนักเรียน <br/>โรงเรียนวัฒโนทัยพายัพ จังหวัดเชียงใหม่</div>
                            <div className='text-left text-white d-none d-md-block d-lg-block d-xl-block d-xxl-block ibm-plex-sans-thai-looped-extraligh' style={{'float':'left','paddingTop':'18px','fontSize':'20px'}}>งานรับนักเรียน โรงเรียนวัฒโนทัยพายัพ จังหวัดเชียงใหม่</div>

                    </Navbar.Brand>
                   
                </Container>
            </Navbar>
        </header>
        </>
    )
}

export default Header;

