import React,{forwardRef,useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {IdentifyTypeName,KeyDownMobile} from '../../Utils/Function';
import parse from 'html-react-parser';

import '../../App.css';

const HomeContent = forwardRef((props,ref) =>{
    const RedirectTo = useNavigate()
    const [_IdentifyTypeID,setIdentifyTypeID] = useState(1);
    const [_IdentifyTypeName,setIdentifyTypeName] = useState(IdentifyTypeName(1));
    const [_IdentifyID,setIdentifyID] = useState(null)
    const [_Mobile,setMobile] = useState(null)
    const [_AnnounceContent,setAnnounceContent] = useState('');
    useEffect(() => {
        (async () => {
            _Announce();
        })();

        return ()=>{ }
    }, []);

    const _Announce = async()=>{
        API({method : "get",url:"/Announce/Last"}).then(res=>{
            setAnnounceContent(res.data);
        }).catch(error=>{
            
        });
    }

    const _IdentifyTypeChange = (e)=>{
        const _val = Number(e.target.value);
        setIdentifyTypeID(_val);
        setIdentifyTypeName(IdentifyTypeName(_val));
    }
    const _ApplicantLogin = async()=>{
        const _reqObj = {
            MobileNumber: _Mobile,
            IdentifyType:_IdentifyTypeID,
            IdentifyID:_IdentifyID
        }
        sessionStorage.clear();
        Swal.hideLoading();
        API({method : "post",url:"/Applicant/Login",data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                
                return;
            }
            sessionStorage.setItem("Authorization", res.data.Response.Authorization);
            sessionStorage.setItem("ApplicantID", res.data.Response.ApplicantID);
            sessionStorage.setItem("ApplicantProfile",JSON.stringify(res.data.Response) );

            Swal.fire({
                icon: 'success',
                title: res.data.Message,
                timer: 2000,
            }).then(()=>{
                window.location.reload();
            });

        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: (res.response != undefined?res.response.data.Message:'การเชื่อมต่อผิดพลาด'),
                text: res.message+' ['+res.code+']',
            });

            return;
        });
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-9 mt-2 mb-2'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title fs-5'> ข่าวประชาสัมพันธ์</div>
                            </div>
                            <div className='card-body'>
                                <div className='row mt-2 mb-2'>
                                    {parse(_AnnounceContent)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 mt-2'>
                        <div className='row mb-3'>
                            <div className='col-12 text-center'>
                                <button type='button' className='btn btn-info text-white btn-lg fs-3' style={{'minHeight':'70px','width':'100%'}} onClick={()=>{RedirectTo("/Applican/Signup")}}>ลงทะเบียนรับระเบียบการสมัคร</button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header bd-blue-100'>
                                        <div className='card-title fs-5'>เข้าสู่ระบบ</div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="mb-3">
                                                <label className="form-label fw-semibold">ประเภทบัตรประจำตัวผู้สมัคร</label>
                                                <select className='form-control'defaultValue={_IdentifyTypeID} value={_IdentifyTypeID} onChange={(e)=>{_IdentifyTypeChange(e);}}>
                                                    <option value={1}>บัตรประจำตัวประชาชน</option>
                                                    <option value={2}>บัตรประจำตัวผู้ไม่มีสัญชาติไทย</option>
                                                    <option value={3}>หนังสือเดินทาง</option>
                                                </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label  className="form-label fw-semibold">{_IdentifyTypeName}</label>
                                                    <input type={_IdentifyTypeID === 1 ? "tel":"text"} className="form-control" placeholder={_IdentifyTypeName} onKeyDown={(e)=>{ if(_IdentifyTypeID == 1) KeyDownMobile(e);}} value={_IdentifyID} onChange={(e)=>{setIdentifyID(e.target.value);}}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">หมายเลขโทรศัพท์มือถือ</label>
                                                    <input type="tel" className="form-control" placeholder='หมายเลขโทรศัพท์มือถือ' value={_Mobile} onKeyDown={(e)=>{KeyDownMobile(e);}} onChange={(e)=>{setMobile(e.target.value);}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 text-end'>
                                                <button type='button' className='btn btn-success btn-lg' style={{width:'150px'}} disabled={(_IdentifyID === null || _IdentifyID.length === 0 || _Mobile === null || _Mobile.length != 10 ?true:false)} onClick={()=>{_ApplicantLogin()}}>เข้าสู่ระบบ</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
});
export default React.memo(HomeContent)