import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../API';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewCard = forwardRef((props,ref) =>{

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [_PDFView,setPDFView] = useState(false);
    const [_ViewTitle,setViewTitle] = useState('PDF Preview');
    const [_PDFFile,setPDFFile] = useState(null);
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(PDFFile,Title){
            setPDFFile(PDFFile);
            if(Title != undefined)
                setViewTitle(Title);
            else
                setViewTitle('PDF Preview');
            setPDFView(true);
        }
      }));

      return (
        <>
        <Modal isOpen={_PDFView} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{_ViewTitle}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setPDFView(false)}}></button>
            </div>
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-12'>
                        {(_PDFView?
                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                            <Viewer fileUrl={_PDFFile} plugins={[defaultLayoutPluginInstance]}/>
                        </Worker>    
                        :"")}
                    </div>
                </div>
            </div>
        </Modal>
        </>
      )
});

export default React.memo(PDFViewCard)