import API from '../API';

const SearchSubDistrict = async (Search)=>{
    return await API.get('/Search/Subdistrict/?Search='+Search,{responseType:'json'}).then((res)=>{
        if(!res.data.Status) return [];
        return res.data.Response;
    }).catch(res => {return [];});
}
const SearchSchool = async (Search)=>{
    return await API.get('/Search/School/?Search='+Search,{responseType:'json'}).then((res)=>{
        if(!res.data.Status) return [];
        return res.data.Response;
    }).catch(res => {return [];});
}
export {SearchSubDistrict,SearchSchool}