import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../API';
import moment from 'moment';

const OTPCard = forwardRef((props,ref) =>{
    const [_OTPModalState,setOTPModalState] = useState(false);
    const [_Mobile,setMobile] = useState('');
    const [_OTPMode,setOTPMode] = useState(1);
    const [_OTPDetail,setOTPDetail] = useState(null);
    const [_OTPExpireTime,setOTPExpireTime] = useState('00:00');
    const [_OTPCode,setOTPCode]= useState('');
    const [_Interval,setInterval] = useState(undefined);
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({
        GetOTP(Mobile){
            setMobile(Mobile);
            setOTPDetail(null);
            setOTPMode(1);
            setOTPCode('');
            setOTPExpireTime('00:00');
            setOTPModalState(true);
        },

      }));
    const _CancelOTP = async()=>{
        setOTPModalState(false);
    }
    const _GetOTP = async()=>{
        Swal.showLoading();
        API({method : "post",url:"/OTP/Request/"+_Mobile}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                if(props.error != undefined){
                    try{
                        props.error();
                    }catch(e){
                        console.log(e);
                    }
                }
                return;
            }
            setOTPDetail(res.data.Response);
            _RunTimeLeft(res.data.Response);
            setOTPMode(2);
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
            if(props.error != undefined){
                try{
                    props.error();
                }catch(e){
                    console.log(e);
                }
            }
        });
    }
    const _RunTimeLeft = (OTPDetail)=>{
            const Interval = setInterval(() => {
                
            }, 1000);
            setTimeout(()=>{
                if(OTPDetail != null){
                    const _ExpireTime = moment(OTPDetail.Expire);
                    const _CurTime = moment();
                    if(_CurTime>_ExpireTime && _OTPMode != 2) {
                        setOTPExpireTime('00:00');
                        _RenewOTP();
                        return;
                    }

                    const _ExpireDuration = _ExpireTime.diff(_CurTime,"seconds");
                    const _ExpireMin = Math.floor(_ExpireDuration/60);
                    const _ExpireStr = _ExpireMin+':'+(_ExpireDuration - (_ExpireMin*60));
                    setOTPExpireTime(_ExpireStr);
                    setTimeout(()=>{_RunTimeLeft(OTPDetail)},500);
                }
            },500);
    }

    const _VeriflyOTP = async()=>{
        if(_OTPCode.length == 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดกรอกรหัส OTP',
            });
            return
        }
        const _reqObj = {
            OTPRefID:_OTPDetail.OTPRefID,
            RefCode:_OTPDetail.RefCode,
            OTPCode:_OTPCode,
        }
        API({method : "post",url:"/OTP/Verifly/"+_Mobile,data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                if(props.error != undefined){
                    try{
                        props.error();
                    }catch(e){
                        console.log(e);
                    }
                }
                return;
            }
            _Update();
            setOTPModalState(false);
            Swal.fire({
                icon: 'success',
                title: res.data.Message,
            }).then(()=>{
                setOTPMode(1);
            });
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
            if(props.error != undefined){
                try{
                    props.error();
                }catch(e){
                    console.log(e);
                }
            }
        });
    }
    const _Update = ()=>{
        if(props.Verifly != undefined && _OTPDetail != null){
            try{
                //props.modal(true,res.data.Data);
                props.Verifly(_OTPDetail.OTPRefID,_OTPDetail.RefCode)
            }catch(e){
                console.log(e);
            }
        }
    }
    const _RenewOTP =()=>{
            setOTPDetail(null);
            setOTPMode(1);
            setOTPCode('');
            setOTPExpireTime('00:00');
            setOTPModalState(true);
    }
    return (
        <>
            <Modal isOpen={_OTPModalState} className={'modal'} centered={true} unmountOnClose={true}>
                <div className="modal-header">
                    <h5 className="modal-title">ตรวจสอบ OTP</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{_CancelOTP()}}></button>
                </div>
                <div className='modal-body'>
                {(_OTPMode === 1 ?
                    <>
                        <div className='row'>
                            <div className='col-12 text-center fw-semibold'>ยืนยันหมายเลขโทรศัพท์</div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12 text-center'>
                                <h4 className='fw-semibold'>{_Mobile}</h4>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4'>
                            <div className='col-12 text-center'>
                                <button type="button" className='form-control btn btn-lg btn-warning text-white' onClick={()=>{_GetOTP();}}>รับรหัส OTP</button>
                            </div>
                        </div>
                    </>:"")}

                    {(_OTPMode === 2 ?
                    <>
                        <div className='row'>
                            <div className='col-12 text-center fw-semibold'>กรุณากรอกเลข OTP ที่ส่งไปยังหมายเลข</div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12 text-center'>
                                <h4 className='fw-semibold'>{_Mobile}</h4>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4'>
                            <div className='col-12 text-center'>
                                <input type='text' className='form-control text-center fs-3 fw-semibold' value={_OTPCode} onChange={(e)=>{setOTPCode(e.target.value);}}/>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4'>
                            <div className='col-12 text-center'>
                                <h5>รหัสอ้างอิง ({_OTPDetail!= null ?_OTPDetail.RefCode:"-"})</h5>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4'>
                            <div className='col-12 text-center'>
                                <h5>กรุณาลองใหม่อีกครั้งใน {_OTPExpireTime} นาที</h5>
                            </div>
                        </div>
                        <div className='row mt-4 mb-4'>
                            <div className='col-12 text-center'>
                                <button type="button" className='form-control btn btn-lg btn-info text-white' disabled={(_OTPCode.length === 0 ? true:false)} onClick={()=>{_VeriflyOTP();}}>ยืนยัน OTP</button>
                            </div>
                        </div>
                    </>:"")}
                </div>
            </Modal>
        </>)
        
});

export default React.memo(OTPCard)