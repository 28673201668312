import React,{useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
const Content =(props) =>{
    // eslint-disable-next-line
    useEffect(() => {
        (async () => {
        })();
        
        return ()=>{ 
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Header/>
            <main role='main' className='flex-shrink-0 mt-2 mb-2'>
            {props.children}
            </main>
            <Footer/>
        </React.Fragment>
    )
}
export default React.memo(Content)